<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="mb-5 text-2xl">
        {{ title }}
      </h1>
      <div class="flex justify-center">
        <img
          v-if="infoModalContent.type === 'Hamburger'"
          :src="handlePresentNFTIcon(infoModalContent.tokenId, infoModalContent.network)"
          class="rounded-lg border border-cyan"
          height="200"
          width="200"
        >
        <img
          v-else-if="infoModalContent.source === 'Cashback'"
          src="~/assets/img/misc/cashback-icon.jpg"
          class="rounded-lg border border-cyan"
          height="200"
          width="200"
        >
        <img
          v-else-if="infoModalContent.source === 'SalonRouge'"
          src="~/assets/img/misc/salon-rouge-gift.jpg"
          class="rounded-lg border border-red"
          width="200"
        >
        <img
          v-else
          src="~/assets/img/misc/gift-icon.png"
          class="rounded-lg border border-cyan"
          height="200"
          width="200"
        >
      </div>
      <div class="text-slate-100 mt-4 animate-slide-down-fade-in04s text-center prose prose-invert mb-5">
        <p class="!leading-tight text-lg">
          You have
          <span class="text-white">{{ text }}</span>
          <span v-if="infoModalContent.source === 'Cashback'"> winback</span>
          waiting for you!
        </p>
        <p v-if="infoModalContent.expires" class="text-sm font-bold">
            Expires
            {{ $dayjs(infoModalContent.expires).fromNow() }}
        </p>
      </div>
      <ButtonButton
        theme="primary-outline"
        class="w-full"
        :is-loading="showConnectModal"
        :disabled="showConnectModal"
        @click.prevent.stop="handleClaimItem()"
      >
        <span class="pr-3">Claim</span>
      </ButtonButton>
    </div>
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useUserStore } from '@/store/user';
import { useNft } from '@/composables/useNft';

const emits = defineEmits(['close',]);

const uiStore = useUiStore();
const {
  infoModalContent,
  showConnectModal,
} = storeToRefs(uiStore);

const {
  handlePresentNFTIcon,
} = useNft();

const userStore = useUserStore();
const { isSalonRougePlayer, } = storeToRefs(userStore);

const title = computed(() => infoModalContent.value.type === 'Hamburger' ? 'You have an unclaimed purchase.' : 'You have received an item!');
const text = computed(() => {
  if (infoModalContent.value.type === 'Cash') {
    return `${infoModalContent.value.amount} ${infoModalContent.value.currencyCode}`;
  }

  if (infoModalContent.value.type === 'Hamburger') {
    let msg = `a MetaWin Digital Access Pass #${infoModalContent.value.tokenId}`;
    if (infoModalContent.value.network) {
      msg += ` (${infoModalContent.value.network})`;
    }

    return msg;
  }

  return 'an item';
});

async function handleClaimItem() {
  if (isSalonRougePlayer.value && (infoModalContent.value.source === 'SalonRouge' || infoModalContent.value.source === 'Cashback')) {
    await navigateTo({ path: '/promotions/salon-rouge', });
  } else {
    await navigateTo({
      path: '/notifications',
      query: {
        tab: 'items',
      },
    });
  }
  emits('close');
}
</script>

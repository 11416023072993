<template>
  <article class="animate-fade-in">
    <div class="text-center block relative">
      <transition name="fade-fast">
        <Spinner v-show="isLoading" class="block w-full py-0 absolute"/>
      </transition>

      <h1
        v-if="modalHeading"
        class="text-2xl tracking-wide font-semibold mb-2 text-center animate-slide-down-fade-in01s"
      >
        {{ modalHeading }}
      </h1>

      <p
        v-if="errorMsg"
        class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center"
      >
        {{ errorMsg }}
      </p>

      <CmsContentStandard
        v-if="query"
        :query="query"
        class="text-slate-100"
        @data-loaded="dataLoaded"
      />

      <ButtonButton
        type="button"
        class="block w-full mt-6"
        @click.prevent.stop="close()"
      >
        Close
      </ButtonButton>
    </div>
  </article>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useUiStore } from '@/store/ui';

export default defineComponent({
  name: 'ModalInfoContentCms',
  props: {
    infoModalContent: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'close',
  ],
  data() {
    return {
      query: null,
      modalHeading: null,
      errorMsg: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, ['infoModalWidthMode', 'showInfoModal',]),
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.infoModalContent?.modalId) { return; }

      this.query = `*[_type == "info-modals" && modalId == "${this.infoModalContent.modalId}"] {
        _id,
        heading,
        content,
        modalId,
        modalWidthMode,
      }[0]
      `;
    },
    close() {
      this.$emit('close');
    },
    dataLoaded(data) {
      this.isLoading = false;
      if (!data) {
        this.close();
        this.modalHeading = 'Something went wrong...';
        this.errorMsg = 'We\'re sorry, but we can\'t find the content you\'re looking for.';
        return;
      }

      this.modalHeading = data.heading;
      this.infoModalWidthMode = data.modalWidthMode;
    },
  },
});
</script>

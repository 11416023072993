<template>
  <button
    type="button"
    tabindex="-1"
    @click="emits('changed', value)"
  >
    <input
      type="checkbox"
      :checked="value"
      class="rounded-sm cursor-pointer bg-slate-700 border-1 border-slate-100 text-cyan transition-all outline-0 focus:outline-none focus:shadow-none focus:ring-0"
      :class="size === 'sm' ? 'p-2' : 'p-3'"
    >
    <span
      class="ml-2 relative"
      :class="size === 'sm' ? 'text-sm top-[1px]' : 'text-base'"
    >
      {{ label }}
    </span>
  </button>
</template>

<script setup>
defineProps({
  label: {
    type: String,
    default: null,
  },
  value: {
    type: Boolean,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
});

const emits = defineEmits([
  'changed',
]);
</script>

<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">
        {{ contentDisplay }}
      </h1>
      <ButtonButton
        type="button"
        class="block w-full mt-12"
        @click.prevent.stop="close()"
      >
        Close
      </ButtonButton>
    </div>
  </article>
</template>

<script>
export default defineComponent({
  name: 'ModalInfoContentWithdrawalConfirmation',
  props: {
    infoModalContent: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'close',
  ],
  computed: {
    contentDisplay() {
      if (this.infoModalContent?.message) {
        return this.infoModalContent.message;
      } else {
        return 'Withdrawal request confirmed';
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>
